<template>
    <div>
        <vue-dropzone
            id="dropzone"
            ref="dropzone"
            :options="dropzoneOptions"
            :use-custom-slot="true"
            @vdropzone-file-added="(file) => $emit('file:change', file)"
            @vdropzone-max-files-exceeded="maxFilesExceeded"
        >
            <div>Click to select a file from your system or drag and drop the file here</div>
        </vue-dropzone>
    </div>
</template>

<script>
import FileSelect from '../../../components/common/File/Select';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    name: 'ModuleUploader',

    components: {
        FileSelect,
        vueDropzone: vue2Dropzone
    },

    data() {
        return {
            error: null,
            dropzoneOptions: {
                url: 'https://api-orchestrate-prod-1231.prod.usms.impartner.io',
                autoQueue: false,
                maxFiles: 1
            }
        };
    },

    methods: {
        template() {
            return `<div class="dz-preview dz-file-preview">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                </div>
                <div class="dz-details">
                    <div class="dz-size"><span data-dz-size></span></div>
                    <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>
            </div>
        `;
        },

        maxFilesExceeded(file) {
            this.clearDropzone();
            this.$refs.dropzone.addFile(file);
        },

        clearDropzone() {
            this.$refs.dropzone.removeAllFiles();
        }
    }
};
</script>

<style lang="scss" scoped>
  #dropzone /deep/ .dz-progress {
    display: none;
  }
</style>
